<template>
    <div>
        <div class="goHome">
            <div>
                <h1>Kechirasiz, xatolik yuz berdi. Sahifa topilmadi.</h1>
            </div>
            <router-link to="/">
                <button class="goHome-button mt-5">
                    Bosh sahifa
                </button>
            </router-link>
        </div>
        <go-back next=""/>
    </div>
</template>

<script>
import GoBack from "@/components/GoBack.vue";

export default {
    name: "NotFoundPage",
    components: {GoBack}
}
</script>

<style scoped>
.goHome {
    text-align: center;
    margin: 200px 0;
    padding-bottom: 20px;
}
.goHome-button {
    padding: 10px 20px;
    background-color: #990098;
    color: #FFFFFF;
    font-size: 20px;
    border-radius: 20px;
    border: 2px #990098 solid;
}

.goHome-button:hover {
    background-color: #80007F;
    font-size: 20px;
}

</style>
